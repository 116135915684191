.dashboard-counter {
  border-radius: 10px !important;
}

.counter-title {
  color: #ffffff !important;
  text-align: center;
}

.counter-value {
  color: #ffffff !important;
  text-align: center;
  margin: 16px !important;
}
