.container {
  background-color: '#F0F2F5';
}

.content-container {
  flex-direction: column;
  margin: 20px !important;
  padding: 24px !important;
  min-height: 240px !important;
  background: white;
  box-shadow: 0px 4px 4px rgba(170, 170, 170, 0.25);
  border-radius: 10px;
}

.form-item {
  &-container {
    & > .label {
      font-weight: 600;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }
  }
}

.ql-container {
  min-height: 300px;
}

.ant-form label {
  font-weight: 600;
}

.ant-select-arrow {
  color: black !important;
}

.breadcrumb-container {
  padding: 16px !important;
  background-color: #fafafa;
}
