.header {
  background-color: white !important;
  font-size: 18px;
  padding: 0 24px !important;
}

.container {
  background-color: '#F0F2F5';
}

.content-container {
  flex-direction: column;
  margin: 20px !important;
  padding: 24px !important;
  min-height: 240px !important;
  background: white;
  box-shadow: 0px 4px 4px rgba(170, 170, 170, 0.25);
  border-radius: 10px;
}

.form-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 24px !important;
  min-height: 360px;
  background: white;
}

.ql-editor {
  min-height: 300px;
}

.ant-form label {
  font-weight: 600;
}

.ant-select-arrow {
  color: black !important;
}

.arrow-icon {
  font-size: 18px;
  color: #3f8fe7 !important;
}

.layout-header {
  font-size: 18px;
  font-weight: 600;
}

.breadcrumb-container {
  padding: 16px !important;
  background-color: #fafafa;
}

.news-content {
  p {
    margin-bottom: 0;
  }
}
