.bubble1 {
  position: absolute;
  left: 0;
  top: 17.6%;
}

.bubble2 {
  position: absolute;
  right: 0;
  top: 10%;
  transform: rotate(-180deg);
  height: 38.7%;
}

.bubble3 {
  position: absolute;
  right: 0;
  top: 65.2%;
  transform: rotate(-180deg);
  height: 26.8%;
}

.login-title {
  font-size: 4rem;
  font-weight: bold;
  color: #fff;
}

.login-subtitle {
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
}

.login-input {
  min-height: 50px;
  border-radius: 30px !important;
}
