.header {
  background-color: white !important;
  font-size: 18px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
}

.arrow-icon {
  font-size: 18px;
  color: #3f8fe7 !important;
}

.layout-header {
  font-size: 18px;
  font-weight: 600;
  width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 16px;
}
